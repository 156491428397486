import React from "react"
import Layout from "../../components/layout"
import ItemWithFinish from "../../components/itemWithFinish"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql } from "gatsby"

const StovingEnamels = ({ data }) => (
  <Layout>
    <Row>
      <Col>
        <h1>Stoving Enamels</h1>
      </Col>
    </Row>
    {/* Stoving Enamel Black */}
    <ItemWithFinish
      heading={"Stoving Enamel Black"}
      data={
        "As a primer coat for exterior & interior surface, modified alkyd, fast drying & pigment based systems is used over metal alloys, engines, brakes, exhaust, vehicle part etc."
      }
      package={"20ltr, 4ltr, 1ltr, 1/2 ltr"}
      color={"Black"}
      finish={"Smooth & Matt"}
      dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
      image={data.syntheticEnamelsMain.childImageSharp.fluid}
    />
    <hr />
    {/* stoving enamel steelgrey */}
    <ItemWithFinish
      heading={"Stoving Enamel Steel Grey"}
      data={
        "As a primer coat for exterior &interior surface, modified alkyd, fast drying & pigment based systems is used over metal alloys, engines, brakes, exhaust, vehicle part etc."
      }
      package={"20ltr, 4ltr, 1ltr, 1/2 ltr"}
      color={"Steel Grey"}
      finish={"Smooth & Matt"}
      dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
      image={data.syntheticEnamelsMain.childImageSharp.fluid}
    />

    <hr />

    {/* stoving enamel white */}
    

    <ItemWithFinish
      heading={"Stoving Enamel White"}
      data={
        "As a primer coat for exterior & interior surface, modified alkyd, fast drying & pigment based systems is used over metal alloys, engines, brakes, exhaust, vehicle part etc."
      }
      package={"20ltr, 4ltr, 1ltr, 1/2 ltr"}
      color={"White"}
      finish={"Smooth & Matt"}
      dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
      image={data.syntheticEnamelsMain.childImageSharp.fluid}
    />
    <hr />
        {/* stoving enamel green */}
    <ItemWithFinish
      heading={"Stoving Enamel Green"}
      data={
        "Interior emulsion having a slight sheen finish, durable, washable, water–based modified acrylic emulsion paint for interior walls. It provides a smooth finish over the interior primer. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces."
      }
      package={"20ltr, 4ltr, 1ltr, 1/2 ltr"}
      color={"Green"}
      finish={"Smooth & Matt"}
      dryingTime={"surface dry: 10-15 minutes, hard dry:8-10 hrs"}
      image={data.syntheticEnamelsMain.childImageSharp.fluid}
    />
  </Layout>
)

export default StovingEnamels

export const query = graphql`
query {
    

    syntheticEnamelsMain: file(relativePath: { eq: "syntheticEnamelsMain.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

     
}`
